import { useState } from 'react';
import { useLoaderData, useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';
import {
  Add as AddIcon,
  Visibility as EyeIcon,
  Refresh as RefreshIcon,
  AssignmentReturned as DownloadReportIcon,
} from '@mui/icons-material';

import { inspectionResource } from '../../../rest';

import PageContainer from '../../../components/PageContainer';
import PageHeader from '../../../components/PageHeader';
import Table from '../../../components/Table';
import Notification from '../../../components/Notification';

import { formatDateOptions } from '../../../components/formatDateOptions';

const headCells = [
  {
    id: 'testDate',
    translationKey: 'inspection.list.testDate',
    sorting: true,
  },
  {
    id: 'place',
    translationKey: 'inspection.list.place',
    sorting: true,
  },
  {
    id: 'goal',
    translationKey: 'inspection.list.goal',
    sorting: true,
  },
  {
    id: 'drone',
    translationKey: 'inspection.list.drone',
    sorting: true,
  },
  {
    id: 'device',
    translationKey: 'inspection.list.device',
    sorting: true,
  },
];

const InspectionList = () => {
  const { formatMessage, formatDate } = useIntl();
  const loaderData = useLoaderData();
  const navigate = useNavigate();

  const _mapInspectionsToTableRows = inspections =>
    inspections.elements.map(inspection => ({
      ...inspection,
      testDate: formatDate(inspection.testDate, formatDateOptions),
    }));

  const [inspections, setInspections] = useState(_mapInspectionsToTableRows(loaderData));
  const [notification, setNotification] = useState({});

  const createInspection = async () => {
    const { id } = await inspectionResource.createNew();
    navigate(`/inspection/${id}/view`);
  };

  const handleGoToViewPage = ({ id }) => navigate(`/inspection/${id}/view`);

  const handleDownloadReport = ({ id }) => {
    window.open(`/api/inspection/${id}/report`, '_blank');
  };

  const tableRowActions = [
    {
      icon: <EyeIcon />,
      onClick: handleGoToViewPage,
      label: formatMessage({ id: 'common.button.view' }),
    },
    {
      icon: <DownloadReportIcon />,
      label: formatMessage({ id: 'inspection.view.download-report' }),
      onClick: handleDownloadReport,
      disabled: row => !row.reportPresent,
    },
  ];

  const handleRefresh = async () => {
    try {
      const fetchedInspections = await inspectionResource.listInspections();

      const mappedInspections = _mapInspectionsToTableRows(fetchedInspections);
      setInspections(mappedInspections);

      setNotification({
        open: true,
        severity: 'success',
        message: formatMessage({ id: 'inspection.list.refresh.success' }),
      });
    } catch (error) {
      setNotification({
        open: true,
        severity: 'error',
        message: formatMessage({ id: 'inspection.list.refresh.error' }),
      });
    }
  };

  const headerActions = [
    {
      isIconButton: true,
      icon: <RefreshIcon />,
      label: formatMessage({ id: 'common.button.refresh' }),
      onClick: handleRefresh,
    },
    {
      icon: <AddIcon />,
      label: formatMessage({ id: 'common.button.create' }),
      onClick: createInspection,
    },
  ];

  return (
    <>
      <PageContainer>
        <PageHeader
          title={formatMessage({ id: 'inspection.list.title' })}
          actions={headerActions}
        />
        <Table
          rows={inspections}
          headCells={headCells}
          actions={tableRowActions}
          defaultSort={{ orderBy: 'testDate', order: 'desc' }}
          noResultMessage={formatMessage({ id: 'inspection.list.empty' })}
        />
      </PageContainer>
      <Notification
        open={notification.open}
        severity={notification.severity}
        message={notification.message}
        onClose={() => setNotification(prev => ({ ...prev, open: false }))}
      />
    </>
  );
};

export const inspectionLoader = async () => {
  try {
    return await inspectionResource.listInspections();
  } catch (error) {
    throw Error('Failed to load inspections!', { cause: error });
  }
};

export default InspectionList;
