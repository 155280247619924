import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Card, CardContent, CardHeader } from '@mui/material';
import log from 'loglevel';

import ReferenceFile from './ReferenceFile';
import MeasurementFiles from './MeasurementFiles';

import { inspectionResource } from '../../../rest';

const mapFile = filename => ({
  name: filename,
  mimeType: filename.endsWith('.csv') ? 'text/csv' : 'image/*',
});

const LOGGER = log.getLogger('FilesCard');

const FilesCard = ({ inspectionId, files, onChange }) => {
  const { formatMessage } = useIntl();

  const [backgroundNoiseFile, setBackgroundNoiseFile] = useState(
    files.backgroundNoiseFile ? mapFile(files.backgroundNoiseFile) : null
  );
  const [whiteNoiseFile, setWhiteNoiseFile] = useState(
    files.whiteNoiseFile ? mapFile(files.whiteNoiseFile) : null
  );
  const [measurements, setMeasurements] = useState((files.measurementFiles || []).map(mapFile));

  const handleReferenceFileSelected = async (type, accepted, rejected, setter) => {
    const [file] = accepted;
    if (file) {
      try {
        await inspectionResource.uploadFile(inspectionId, type, file);
        setter(file);
      } catch (error) {
        LOGGER.error('Failed to upload reference file!', error);
      }
    }
  };

  const handleAddNewMeasurementFiles = async newFiles => {
    newFiles.forEach(async file => {
      try {
        await inspectionResource.uploadFile(inspectionId, 'MEASUREMENT', file);
        setMeasurements(prev => [...prev.filter(prevFile => prevFile.name !== file.name), file]);
      } catch (error) {
        LOGGER.error('Failed to upload measurement file!', error);
      }
    });
  };

  const handleRemoveReferenceFile = async (type, filename, setter) => {
    try {
      await inspectionResource.deleteFile(inspectionId, type, filename);
      setter(null);
    } catch (error) {
      LOGGER.error('Failed to remove reference file!', error);
    }
  };

  const handleRemoveMeasurementFile = async filename => {
    try {
      await inspectionResource.deleteFile(inspectionId, 'MEASUREMENT', filename);
      setMeasurements(prev => prev.filter(file => file.name !== filename));
    } catch (error) {
      LOGGER.error('Failed to remove measurement file!', error);
    }
  };

  useEffect(() => {
    onChange({
      hasBackgroundNoiseFile: !!backgroundNoiseFile,
      hasWhiteNoiseFile: !!whiteNoiseFile,
      hasMeasurementFiles: measurements.length > 0,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [backgroundNoiseFile, whiteNoiseFile, measurements.length]);

  return (
    <Card>
      <CardHeader title={formatMessage({ id: 'inspection.view.files.title' })} />
      <CardContent>
        <ReferenceFile
          referenceType="BACKGROUND_NOISE"
          fileTypeLabel={formatMessage({
            id: 'inspection.view.files.background-noise-spectrum',
          })}
          file={backgroundNoiseFile}
          onChange={(type, accepted, rejected) =>
            handleReferenceFileSelected(type, accepted, rejected, setBackgroundNoiseFile)
          }
          onRemove={(type, filename) =>
            handleRemoveReferenceFile(type, filename, setBackgroundNoiseFile)
          }
        />
        <ReferenceFile
          referenceType="WHITE_NOISE"
          fileTypeLabel={formatMessage({ id: 'inspection.view.files.white-noise-spectrum' })}
          file={whiteNoiseFile}
          onChange={(type, accepted, rejected) =>
            handleReferenceFileSelected(type, accepted, rejected, setWhiteNoiseFile)
          }
          onRemove={(type, filename) =>
            handleRemoveReferenceFile(type, filename, setWhiteNoiseFile)
          }
        />
        <MeasurementFiles
          files={measurements}
          onNewFiles={handleAddNewMeasurementFiles}
          onRemove={handleRemoveMeasurementFile}
        />
      </CardContent>
    </Card>
  );
};

FilesCard.defaultProps = {
  files: {},
};

export default FilesCard;
