import { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useLoaderData, useParams } from 'react-router-dom';
import { Assignment, AssignmentReturned, Autorenew } from '@mui/icons-material';

import PageContainer from '../../../components/PageContainer';
import PageHeader from '../../../components/PageHeader';
import MetadataCard from '../../../components/Inspection/Metadata';
import FilesCard from '../../../components/Inspection/Files';

import { inspectionResource, reportResource } from '../../../rest';

import './style.scss';

const InspectionView = () => {
  const { id } = useParams();
  const { locale, formatMessage } = useIntl();

  const [inspection, setInspection] = useState(useLoaderData());
  const [filesState, setFilesState] = useState({
    hasBackgroundNoiseFile: !!inspection.files?.backgroundNoiseFile,
    hasWhiteNoiseFile: !!inspection.files?.whiteNoiseFile,
    hasMeasurementFiles: inspection.files?.measurementFiles?.length > 0,
  });

  const handleGenerateReport = async () => {
    try {
      await reportResource.generate({ inspectionId: id });
      setInspection(prev => ({ ...prev, metadata: { ...prev.metadata, reportPresent: true } }));
    } catch (error) {
      throw Error('Failed to generate report!', { cause: error });
    }
  };

  const handleDownloadReport = () => {
    window.open(`/api/inspection/${id}/report`, '_blank');
  };

  const handleMetadataChange = metadata => {
    setInspection(prev => ({ ...prev, metadata }));
  };

  const handleFilesChange = state => setFilesState(state);

  const headerActions = useMemo(
    () => [
      {
        icon: inspection.metadata.reportPresent ? <Autorenew /> : <Assignment />,
        label: formatMessage({
          id: inspection.metadata.reportPresent
            ? 'inspection.view.regenerate-report'
            : 'inspection.view.generate-report',
        }),
        onClick: handleGenerateReport,
        disabled:
          !filesState.hasBackgroundNoiseFile ||
          !filesState.hasWhiteNoiseFile ||
          !filesState.hasMeasurementFiles,
      },
      {
        icon: <AssignmentReturned />,
        label: formatMessage({ id: 'inspection.view.download-report' }),
        onClick: handleDownloadReport,
        disabled: !inspection.metadata.reportPresent,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [locale, formatMessage, handleGenerateReport, filesState, inspection, handleDownloadReport]
  );

  return (
    <PageContainer className="inspection-view">
      <PageHeader
        title={formatMessage({ id: 'inspection.view.title' }, { id })}
        actions={headerActions}
      />
      <MetadataCard metadata={inspection.metadata} onChange={handleMetadataChange} />
      <FilesCard inspectionId={id} files={inspection.files} onChange={handleFilesChange} />
    </PageContainer>
  );
};

export const inspectionViewLoader = async ({ params: { id } }) => {
  try {
    return await inspectionResource.getById(id);
  } catch (error) {
    throw Error(`Failed to get inspection (id: ${id})!`, { cause: error });
  }
};

export default InspectionView;
