import { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { DatePicker } from '@mui/x-date-pickers';
import moment from 'moment/moment';

import Input from '../../../Input';

import './style.scss';

const MetadataEdit = ({ metadata, onChange }) => (
  <div className="metadata">
    <span className="field">
      <FormattedMessage id="inspection.view.metadata.testDate" />
    </span>
    <span className="value">
      <DatePicker
        format="YYYY. MM. DD."
        slotProps={{ textField: { placeholder: '' } }}
        value={moment(metadata.testDate)}
        onChange={value => {
          const formatted = value.format('YYYY-MM-DD');
          onChange('testDate', formatted);
        }}
      />
    </span>
    {['goal', 'place', 'people', 'drone', 'device'].map(field => (
      <Fragment key={field}>
        <span className="field">
          <FormattedMessage id={`inspection.view.metadata.${field}`} />
        </span>
        <Input
          value={metadata[field] || ''}
          onChange={event => onChange(field, event.target.value)}
        />
      </Fragment>
    ))}
    <span className="field notes">
      <FormattedMessage id="inspection.view.metadata.notes" />
    </span>
    <Input
      className="notes-area"
      multiline
      minRows="3"
      value={metadata.notes || ''}
      onChange={event => onChange('notes', event.target.value)}
    />
  </div>
);

export default MetadataEdit;
