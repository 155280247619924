import { FormattedMessage, useIntl } from 'react-intl';
import { FolderOpen } from '@mui/icons-material';

import FileUpload from '../../../FileUpload';
import UploadedFile from '../../../UploadedFile';

const MeasurementFiles = ({ files, onNewFiles, onRemove }) => {
  const { formatMessage } = useIntl();

  const handleFilesSelected = accepted => onNewFiles(accepted);

  return (
    <div className="files-wrapper">
      <span className="file-type">
        <FolderOpen />
        <FormattedMessage id="inspection.view.files.measurements" />
      </span>
      <div className="files">
        {files
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((file, idx) => (
            <UploadedFile key={idx} file={file} onRemove={onRemove} />
          ))}
        <FileUpload
          accept={['csv', 'image']}
          multiple
          onFilesSelected={handleFilesSelected}
          uploadLabel={formatMessage({ id: `inspection.view.upload.measurements` })}
        />
      </div>
    </div>
  );
};

export default MeasurementFiles;
