import { api } from '../rest';

const createNew = async () => {
  const { data } = await api.post('/inspection');
  return data;
};

const getById = async id => {
  const { data } = await api.get(`/inspection/${id}`);
  return data;
};

const listInspections = async () => {
  const { data } = await api.get('/inspection');
  return data;
};

const update = async (id, dto) => {
  const { data } = await api.put(`/inspection/${id}`, dto);
  return data;
};

const uploadFile = async (id, type, file) => {
  const formData = new FormData();
  formData.append('file', file);

  await api.put(`/inspection/${id}/attachment`, formData, { params: { type } });
};

const deleteFile = async (id, type, filename) => {
  await api.delete(`/inspection/${id}/attachment`, { params: { type, fileName: filename } });
};

export default {
  createNew,
  getById,
  listInspections,
  update,
  uploadFile,
  deleteFile,
};
