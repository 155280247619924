import { useOutlet } from 'react-router-dom';
import Header from './Header';

import './style.scss';

const Layout = () => {
  const outlet = useOutlet();

  return (
    <>
      <Header />
      <main>{outlet}</main>
      <footer></footer>
    </>
  );
};

export default Layout;
