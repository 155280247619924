import { useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { Box, Typography, Tooltip, IconButton, Button, InputAdornment } from '@mui/material';
import { Search as SearchIcon, ArrowBack as ArrowBackIcon } from '@mui/icons-material';

import Input from '../Input';
import './style.scss';

const PageHeader = ({ title, searchBar, backButton, filter, actions, children }) => {
  const navigate = useNavigate();
  const { formatMessage } = useIntl();

  return (
    <Box>
      <Box className="page-header">
        <Typography className="page-title" variant="h4">
          {backButton && (
            <Tooltip title={formatMessage({ id: 'common.button.back' })}>
              <IconButton
                edge="end"
                onClick={() => navigate(backButton.route ? backButton.route : -1)}
                className="back-button"
              >
                <ArrowBackIcon />
              </IconButton>
            </Tooltip>
          )}
          {title}
          {filter && (
            <Tooltip title={filter.label}>
              <IconButton onClick={filter.onClick}>{filter.icon}</IconButton>
            </Tooltip>
          )}
        </Typography>

        {searchBar && (
          <Input
            type="text"
            size="small"
            className="search-input"
            InputProps={{
              endAdornment: (
                <InputAdornment position="start" onClick={searchBar.adornmentProps.onClick}>
                  <IconButton edge="end">
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            {...searchBar.inputProps}
          />
        )}

        {actions && (
          <Box className="actions-container">
            {actions.map((action, index) => {
              const { isIconButton, label, icon, onClick, disabled } = action;

              return isIconButton ? (
                <Tooltip key={index} title={label}>
                  <IconButton onClick={onClick}>{icon}</IconButton>
                </Tooltip>
              ) : (
                <Button
                  key={index}
                  variant="contained"
                  type="button"
                  startIcon={icon}
                  onClick={onClick}
                  disabled={disabled}
                >
                  {label}
                </Button>
              );
            })}
          </Box>
        )}
        {children}
      </Box>
    </Box>
  );
};

export default PageHeader;
