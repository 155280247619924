import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link, useLoaderData, useNavigate } from 'react-router-dom';
import {
  AppBar,
  Avatar,
  Box,
  Divider,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from '@mui/material';
import {
  PersonOutline as ProfilePictureIcon,
  LogoutOutlined as LogoutIcon,
} from '@mui/icons-material';

import { FlagIconHU, FlagIconGB } from '../../../components/Flag';

import { useLocale } from '../../../context/LocaleContext';
import { authenticationResource } from '../../../rest';

import './style.scss';

const languages = {
  hu: FlagIconHU,
  en: FlagIconGB,
};

const Header = () => {
  const navigate = useNavigate();
  const { locale, changeLocale } = useLocale();
  const user = useLoaderData();

  const [profileMenuAnchorElement, setProfileMenuAnchorElement] = useState(null);
  const profileMenuOpen = Boolean(profileMenuAnchorElement);
  const [languageMenuAnchorElement, setLanguageMenuAnchorElement] = useState(null);
  const languageMenuOpen = Boolean(languageMenuAnchorElement);

  const handleOpenProfileMenu = event => setProfileMenuAnchorElement(event.currentTarget);

  const handleCloseProfileMenu = () => setProfileMenuAnchorElement(null);

  const handleOpenLanguageMenu = event => setLanguageMenuAnchorElement(event.currentTarget);

  const handleCloseLanguageMenu = () => setLanguageMenuAnchorElement(null);

  const handleChangeLanguage = newLocale => {
    changeLocale(newLocale);
    handleCloseLanguageMenu();
  };

  const handleLogOut = async () => {
    await authenticationResource.logout();
    navigate('login');
  };

  const CurrentLanguageIcon = languages[locale];

  return (
    <AppBar className="header">
      <Toolbar>
        <Link to="/">
          <Typography className="app-title" variant="h6">
            <FormattedMessage id="header.project" />
          </Typography>
        </Link>

        <Box className="placeholder" />

        <Box className="menu-wrapper">
          <IconButton className="language-menu-button" onClick={handleOpenLanguageMenu}>
            <CurrentLanguageIcon className="current-language-icon" />
            <Typography className="current-language-abbr" variant="button">
              <FormattedMessage id={`header.language.${locale}.abbr`} />
            </Typography>
          </IconButton>

          <Menu
            className="language-menu"
            anchorEl={languageMenuAnchorElement}
            open={languageMenuOpen}
            onClose={handleCloseLanguageMenu}
          >
            {Object.keys(languages).map((key, index) => {
              const LanguageIcon = languages[key];
              return (
                <MenuItem onClick={() => handleChangeLanguage(key)} key={index}>
                  <ListItemIcon>
                    <LanguageIcon fontSize="small" />
                  </ListItemIcon>
                  <FormattedMessage id={`header.language.${key}.label`} />
                </MenuItem>
              );
            })}
          </Menu>

          <IconButton className="profile-menu-button" onClick={handleOpenProfileMenu}>
            <Avatar className="profile-picture" src={user.profilePicture}>
              {/* Fallback icon */}
              <ProfilePictureIcon color="primary" />
            </Avatar>
          </IconButton>

          <Menu
            className="profile-menu"
            anchorEl={profileMenuAnchorElement}
            open={profileMenuOpen}
            onClose={handleCloseProfileMenu}
          >
            <Box className="profile-menu-header">
              <Typography className="username" variant="body1">
                {user.name}
              </Typography>
              <Typography variant="body2">{user.email}</Typography>
            </Box>
            <Divider className="profile-menu-item-divider" />
            <MenuItem onClick={handleLogOut}>
              <ListItemIcon>
                <LogoutIcon fontSize="small" />
              </ListItemIcon>
              <FormattedMessage id="header.profile.log-out" />
            </MenuItem>
          </Menu>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
