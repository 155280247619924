import { IconButton } from '@mui/material';
import { ContentPaste, Delete, PhotoCamera } from '@mui/icons-material';

import './style.scss';

const UploadedFile = ({ file, onRemove }) => (
  <span className="uploaded-file">
    {file.mimeType.startsWith('text/') && <ContentPaste />}
    {file.mimeType.startsWith('image/') && <PhotoCamera />}
    <span className="filename">{file.name}</span>
    <span className="file-actions">
      <IconButton onClick={() => onRemove(file.name)}>
        <Delete />
      </IconButton>
    </span>
  </span>
);

export default UploadedFile;
