import { useIntl } from 'react-intl';
import { FolderOpen } from '@mui/icons-material';

import FileUpload from '../../../FileUpload';
import UploadedFile from '../../../UploadedFile';

const ReferenceFile = ({ referenceType, fileTypeLabel, file, onChange, onRemove }) => {
  const { formatMessage } = useIntl();

  const handleFileSelected = accepted => {
    const [newFile] = accepted;
    if (newFile) {
      onChange(referenceType, [newFile]);
    }
  };

  const handleFileRemoved = filename => onRemove(referenceType, filename);

  return (
    <div className="files-wrapper">
      <span className="file-type">
        <FolderOpen />
        {fileTypeLabel}
      </span>
      <div className="files">
        {file ? (
          <UploadedFile file={file} onRemove={handleFileRemoved} />
        ) : (
          <FileUpload
            accept="csv"
            onFilesSelected={handleFileSelected}
            uploadLabel={formatMessage({ id: `inspection.view.upload.csv` })}
          />
        )}
      </div>
    </div>
  );
};
export default ReferenceFile;
