import { Fragment } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { formatDateOptions } from '../../../formatDateOptions';

const MetadataView = ({ metadata }) => {
  const { formatDate } = useIntl();

  return (
    <div className="metadata">
      <span className="field">
        <FormattedMessage id="inspection.view.metadata.testDate" />
      </span>
      <span className="value">{formatDate(metadata.testDate, formatDateOptions)}</span>
      {['goal', 'place', 'people', 'drone', 'device', 'notes'].map(field => (
        <Fragment key={field}>
          <span className="field">
            <FormattedMessage id={`inspection.view.metadata.${field}`} />
          </span>
          <span className="value">{metadata[field]}</span>
        </Fragment>
      ))}
    </div>
  );
};

export default MetadataView;
