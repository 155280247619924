import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Route,
  RouterProvider,
} from 'react-router-dom';

import { authenticationResource } from '../../rest';

import Login from '../Login';
import Layout from '../Layout/Layout';

import InspectionList, { inspectionLoader } from '../Inspection/List';
import InspectionView, { inspectionViewLoader } from '../Inspection/View';

const userLoader = async (throwError = true) => {
  try {
    const fetchedUser = await authenticationResource.getCurrentUser();
    return fetchedUser;
  } catch (error) {
    if (throwError) {
      throw Error('Failed to get user!');
    }

    return null;
  }
};

const AppRouter = () => {
  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route path="/">
        <Route
          id="private-root"
          path="/"
          element={<Layout />}
          loader={userLoader}
          // Revalidate in case of profile data change, because header should be updated!
          shouldRevalidate={({ formData }) => formData?.get('action-type') === 'update-profile'}
          errorElement={<Navigate to="login" />}
        >
          <Route path="/" element={<Navigate to="inspection" replace />} />

          <Route
            path="inspection/:id/view"
            element={<InspectionView />}
            loader={inspectionViewLoader}
          />
          <Route path="inspection" element={<InspectionList />} loader={inspectionLoader} />
        </Route>

        <Route path="login" element={<Login />} loader={() => userLoader(false)} />
      </Route>
    )
  );

  return <RouterProvider router={router} />;
};

export default AppRouter;
