import { useMemo } from 'react';
import Dropzone from 'react-dropzone';
import { Upload } from '@mui/icons-material';

import './style.scss';

const csvMimeTypes = { 'text/csv': ['.csv'] };
const imageMimeTypes = { 'image/png': ['.png'], 'image/jpeg': ['.jpg', '.jpeg'] };

const FileUpload = ({ accept, maxFileSize, multiple, onFilesSelected, uploadLabel }) => {
  const acceptedMimeTypes = useMemo(() => {
    const csvTypes = accept.includes('csv') ? csvMimeTypes : [];
    const imageTypes = accept.includes('image') ? imageMimeTypes : [];
    return { ...csvTypes, ...imageTypes };
  }, [accept]);

  const onFileDrop = (acceptedFiles, rejectedFiles) => {
    const accepted = acceptedFiles.map(file =>
      Object.assign(file, {
        mimeType: file.type,
      })
    );

    const rejected = rejectedFiles?.flatMap(f => f.errors).map(({ code }) => code);

    onFilesSelected(accepted, rejected);
  };

  return (
    <div className="file-upload">
      <Dropzone
        accept={acceptedMimeTypes}
        maxSize={maxFileSize}
        multiple={multiple}
        onDrop={onFileDrop}
      >
        {({ getRootProps, getInputProps }) => (
          <div {...getRootProps()}>
            <input {...getInputProps()} />
            <span className="upload-placeholder">
              <Upload />
              <span>{uploadLabel}</span>
            </span>
          </div>
        )}
      </Dropzone>
    </div>
  );
};

FileUpload.defaultProps = {
  maxFileSize: 104857600,
};

export default FileUpload;
