import { useState } from 'react';
import { useIntl } from 'react-intl';
import { Check, Edit, Undo } from '@mui/icons-material';
import { Card, CardContent, CardHeader, IconButton } from '@mui/material';

import MetadataView from './View';
import MetadataEdit from './Edit';

import { inspectionResource } from '../../../rest';

const MODES = {
  VIEW: 'view',
  EDIT: 'edit',
};

const MetadataCard = ({ metadata, onChange }) => {
  const { formatMessage } = useIntl();

  const [metadataDraft, setMetadataDraft] = useState();
  const [mode, setMode] = useState(MODES.VIEW);

  const enterEditMode = () => {
    setMetadataDraft({ ...metadata });
    setMode(MODES.EDIT);
  };

  const exitEditMode = () => setMode(MODES.VIEW);

  const handleDraftChange = (field, value) => {
    setMetadataDraft(prev => ({ ...prev, [field]: value }));
  };

  const handleSaveMetadata = async () => {
    const newMetadata = await inspectionResource.update(metadata.id, metadataDraft);

    onChange(newMetadata);
    setMode(MODES.VIEW);
  };

  return (
    <Card>
      <CardHeader
        title={formatMessage({ id: 'inspection.view.metadata.title' })}
        action={
          mode === MODES.EDIT ? (
            <>
              <IconButton onClick={exitEditMode}>
                <Undo />
              </IconButton>
              <IconButton onClick={handleSaveMetadata}>
                <Check />
              </IconButton>
            </>
          ) : (
            <IconButton onClick={enterEditMode}>
              <Edit />
            </IconButton>
          )
        }
      />
      <CardContent>
        {mode === MODES.EDIT ? (
          <MetadataEdit metadata={metadataDraft} onChange={handleDraftChange} />
        ) : (
          <MetadataView metadata={metadata} />
        )}
      </CardContent>
    </Card>
  );
};

export default MetadataCard;
